<template>
	<div class="download-page">
		<van-image
			fit="contain"
			:src="resourceURL + 'images/invite/logo.png'"
			class="logo"
		/>

		<van-overlay :show="isWeiXin">
			<div class="wrapper">
				<div class="weixin-browser"></div>
			</div>
		</van-overlay>

		

		<van-row class="buttons">
			<van-col span="24">
				<van-button :icon="resourceURL + 'images/invite/icon_android.png'" type="default" block round color="#FE6D01" plain size="large" @click="androidDownload">Android 下载</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		name: 'download_android',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				isWeiXin: false,
			}
		},

		mounted() {
			document.title = '华博证券APP下载'

			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//alert(JSON.stringify(ua))
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWeiXin = true
			} else{
				this.isWeiXin = false
			}
		},
		
		methods: {
			
			androidDownload() {
				window.location.href= this.$axios.defaults.baseURL + 'download_download/android'			
			}
		},
	}
</script>